import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { Prompt, useHistory } from "react-router-dom";
import { GET_HISTORY_ROUTE } from "../../../../lib/Router/routes";
import { CREATE_HISTORY_MUTATION } from "../../../../graphql/mutations";
import { historySchema } from "../../../../helpers/validators/historySchema";
import { IHistoryForm } from "../../../../interfaces/forms/IHistoryForm";
import { ICreateHistoryMutationInput, ICreateHistoryMutationResult } from "../../../../interfaces/mutations/ICreateHistoryMutation";
import FieldError from "../../../_common/Form/FieldError/FieldError";
import WYSIWYGEditor from "../../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";
import FormSubTitle from "../../../_common/Form/FormSubTitle/FormSubTitle";
import PrisonCampsSelectInput from "../../../PrisonCamp/PrisonCampsSelectInput/PrisonCampsSelectInput";
import PersonsSelectInput from "../../../Person/PersonsSelectInput/PersonsSelectInput";
import { handleMutationError, mutationIsSuccessful, handleMutationSuccess } from "../../../../helpers/formHelpers";
import { IPrisonCampOption } from "../../../../interfaces/domain/IPrisonCamp";
import { IPersonOption } from "../../../../interfaces/domain/IPerson";
import DateFields from "../../../_common/Form/DateFields/DateFields";

const CreateHistoryForm: React.FunctionComponent = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState, getValues, setValue, control } = useForm<IHistoryForm>({
    validationSchema: historySchema,
    mode: "onChange",
  });

  const [createHistory] = useMutation<ICreateHistoryMutationResult, ICreateHistoryMutationInput>(CREATE_HISTORY_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await createHistory({
      variables: {
        input: {
          title: formValues.title,
          slug: formValues.slug,
          description: formValues.description,
          text: formValues.text,
          author: formValues.author,
          authorEmail: formValues.authorEmail,
          persons: formValues.persons?.map((person) => {
            return { id: person.value, firstName: person.label } as IPersonOption;
          }),
          prisonCamps: formValues.prisonCamps?.map((prisonCamp) => {
            return { id: prisonCamp.value, name: prisonCamp.label } as IPrisonCampOption;
          }),
          internalRemark: formValues.internalRemark,
          endDate: formValues.endDate,
          startDate: formValues.startDate,
          listPersons: formValues.listPersons,
          status: formValues.status,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createHistory)) {
          handleMutationSuccess(result.data);

          history.push(GET_HISTORY_ROUTE(result.data.createHistory.id));
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Prompt
        when={formState.dirty && !formState.isSubmitting}
        message="Du har endringer som ikke er lagret. Er du sikker på du vil fortsette? Endringene vil gå tapt hvis du fortsetter uten å lagre.
        Trykk OK for å gå videre uten å lagre, eller trykk Avbryt for å gå tilbake."
      />
      <div className="row">
        <div className="col-lg-7">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="form-group">
                <label className="required-field" htmlFor="title">
                  Tittel
                </label>
                <input type="text" name="title" ref={register} className={`form-control ${errors.title ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.title}></FieldError>
              </div>

              <div className="form-group">
                <label className="required-field" htmlFor="slug">
                  Unik URL-vennlig tittel
                </label>
                <input type="text" name="slug" ref={register} className={`form-control  ${errors.slug ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.slug}></FieldError>
              </div>
              <div className="form-group">
                <label className="" htmlFor="author">
                  Forfatter
                </label>
                <input type="text" name="author" ref={register} className={`form-control  ${errors.author ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.author}></FieldError>
              </div>
              <div className="form-group">
                <label className="" htmlFor="authorEmail">
                  Epost til forfatter
                </label>
                <input
                  type="email"
                  name="authorEmail"
                  ref={register}
                  placeholder="E-post"
                  autoComplete="off"
                  className={`form-control  ${errors.authorEmail ? "is-invalid" : "valid"}`}
                />

                <FieldError error={errors.authorEmail}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="description">Ingress</label>
                <textarea name="description" ref={register} className={`form-control ${errors.description ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.description}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="internalRemark">Intern merknad</label>
                <textarea
                  name="internalRemark"
                  ref={register}
                  className={`form-control ${errors.internalRemark ? "is-invalid" : "valid"}`}
                />

                <FieldError error={errors.internalRemark}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="status">Status</label>
                <textarea name="status" ref={register} className={`form-control ${errors.status ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.status}></FieldError>
              </div>

              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    placeholder="listPersons"
                    id="listPersons"
                    name="listPersons"
                    ref={register}
                  />
                  <label className="form-check-label" htmlFor="listPersons">
                    Liste over personer?
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="startDate">Startdato</label>
                <DateFields fieldName="startDate" register={register} errors={errors.startDate}></DateFields>
              </div>

              <div className="form-group">
                <label htmlFor="endDate">Sluttdato</label>
                <DateFields fieldName="endDate" register={register} errors={errors.endDate}></DateFields>
              </div>

              <WYSIWYGEditor
                fieldName="text"
                label="Innhold"
                register={register}
                errors={errors}
                setValue={setValue}
                currentValue={getValues().text}
              />
            </div>
            <div className="card-footer">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
                  {formState.isSubmitting ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Oppretter...</span>
                    </>
                  ) : (
                    <span>Opprett</span>
                  )}
                </button>
                <div>
                  <small className="text-muted">Innholdet vil bli publisert</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="card shadow-sm">
            <div className="card-body">
              <FormSubTitle>Tilknyttede personer</FormSubTitle>
              <PersonsSelectInput fieldName="persons" control={control} errors={errors} currentValues={getValues().persons} />

              <FormSubTitle>Tilknyttede fangesteder</FormSubTitle>
              <PrisonCampsSelectInput fieldName="prisonCamps" control={control} errors={errors} currentValues={getValues().prisonCamps} />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateHistoryForm;
