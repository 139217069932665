import React, { useCallback, useEffect, useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./WYSIWYGEditor.scss";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";
import FieldValidationError from "../FieldError/FieldError";

interface WYSIWYGEditorProps {
  fieldName: string;
  label?: string;
  register: (options: { name: string }) => void;
  setValue: (name: string, value: string, shouldValidate?: boolean) => void;
  errors?: NestDataObject<any, FieldError>;
  currentValue?: string;
  size?: "small" | "medium" | "large";
  initialValue?: string;
  placeholder?: string;
}

const BlockEmbed = Quill.import("blots/block/embed");

class CustomImageBlot extends BlockEmbed {
  static blotName = "image";
  static tagName = "img"; // Use <img> as the tag for images

  static create(value: any) {
    const node = super.create();

    if (typeof value === "string") {
      // Set the image source to the provided URL
      node.setAttribute("src", value);
      node.setAttribute("alt", "Image");
      node.setAttribute("style", "max-width: 100%; height: auto; display: block;");
    } else if (value.url) {
      // Handle object values
      node.setAttribute("src", value.url);
      node.setAttribute("alt", value.alt || "Image");
      node.setAttribute("style", "max-width: 100%; height: auto; display: block;");
    }

    return node;
  }

  static value(node: HTMLElement) {
    return node.getAttribute("src") || "";
  }
}

class CustomVideoBlot extends BlockEmbed {
  static blotName = "video";
  static tagName = "div";
  static create(value: any) {
    const node = super.create();

    node.innerHTML = "";

    if (typeof value === "string" && value.includes("https://media.digitalarkivet")) {
      const video = document.createElement("video");
      video.setAttribute("src", value);
      video.setAttribute("controls", "true");
      video.setAttribute("style", "width: 100%; max-width: 600px");
      node.appendChild(video);
    } else if (typeof value === "string") {
      const iframe = document.createElement("iframe");
      iframe.setAttribute("src", value);
      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture");
      iframe.setAttribute("style", "width: 100%; height: 300px; max-width: 600px");
      iframe.setAttribute("allowfullscreen", "true");
      node.appendChild(iframe);
    } else if (value.url) {
      const iframe = document.createElement("iframe");
      iframe.setAttribute("src", value.url);
      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture");
      iframe.setAttribute("allowfullscreen", "true");
      iframe.setAttribute("style", "width: 100%; height: 300px;");
      node.appendChild(iframe);
    }

    return node;
  }

  static value(node: HTMLElement) {
    const iframe = node.querySelector("iframe");
    const video = node.querySelector("video");

    if (video) {
      return video.getAttribute("src") || "";
    }

    if (iframe) {
      return iframe.getAttribute("src") || "";
    }

    return {};
  }
}

// Register the custom video blot
Quill.register(CustomVideoBlot);
Quill.register(CustomImageBlot);


const WYSIWYGEditor: React.FC<WYSIWYGEditorProps> = ({
  fieldName,
  label,
  register,
  setValue,
  errors,
  initialValue = "",
  placeholder = "",
  size = "medium",
}) => {
  const quillRef = React.useRef<ReactQuill>(null);
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "size",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "background",
    "link",
    "image",
    "video",
    "script",
  ];

  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "code-block"],
    ["link", "image"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["video"],
    ["clean"],
  ];


  const referenceHandler = useCallback((type: string) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const tooltip = (quill as any).theme?.tooltip; // Cast to 'any' to access the theme

      if (!tooltip) {
        console.error("Tooltip is not available in the current theme.");
        return;
      }

      const originalSave = tooltip.save;
      const originalHide = tooltip.hide;

      tooltip.save = function() {
        const range = quill.getSelection(true);
        const value = tooltip.textbox.value;
        if (value) {
          quill.insertEmbed(range.index, type, value, "user");
        }
        tooltip.hide();
      };

      tooltip.hide = function() {
        tooltip.save = originalSave;
        tooltip.hide = originalHide;
        originalHide.call(tooltip);
      };
      const label = tooltip.root.querySelector(".ql-tooltip-editor-label");
      if (label) {
        label.textContent = "URL"; // Update the label text
      }

      tooltip.edit(type);
      tooltip.textbox.placeholder = "Legg til URL";
    }
  }, [quillRef]);

  const modules = useMemo(() => ({
    toolbar: {
      container: toolbarOptions,
      handlers: {
        image: () => referenceHandler("image"),
        video: () => referenceHandler("video")
      }
    },
    clipboard: { matchVisual: false },
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: true,
    },
  }), []);


  useEffect(() => {
    register({ name: fieldName });
  }, [register, fieldName]);

  const handleChange = (html: string) => {
    setValue(fieldName, html, true);
  };


  return (
    <div>
      {label && (
        <label htmlFor={fieldName} className="wysiwyg-editor__label">
          {label}
        </label>
      )}

      <ReactQuill
        ref={quillRef}
        className="custom-quill"
        theme="snow"
        defaultValue={initialValue}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        bounds=".app"
        placeholder={placeholder}
      />

      {errors?.[fieldName] && <FieldValidationError error={errors[fieldName]?.message || "Invalid input"} />}

      <style>
        {`
          .ql-toolbar .ql-formats button {
            font-size: 10px;
            padding: 4px 4px;
          }

          .ql-toolbar select {
            margin-left: 4px;
            font-size: 10px;
            padding: 4px;
          }

          .ql-container {
            min-height: ${size === "medium" ? "350px" : size === "small" ? "200px" : "500px"};
          }
        `}
      </style>
    </div>
  );
};

export default WYSIWYGEditor;
