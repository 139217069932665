import React, { useEffect, useRef, useState } from "react";
import { IEntityReference } from "../../../interfaces/domain/IEntityReference";
import { IEntityReferenceTypeEnum } from "../../../interfaces/forms/IEntityReferenceForm";
import { GetReadableReferenceCategoryName } from "../../../helpers/enumHelpers";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import { Link } from "react-router-dom";
import { GET_REFERENCE_ROUTE } from "../../../lib/Router/routes";
import { FaCopy } from "react-icons/fa";
import { Overlay, Tooltip } from "react-bootstrap";
import { IReference } from "../../../interfaces/domain/IReference";
import { useLazyQuery } from "@apollo/client";
import { GET_REFERENCE_QUERY } from "../../../graphql/queries";
import "./Reference.scss";
import { IPrisonCamp } from "../../../interfaces/domain/IPrisonCamp";
import { IPerson } from "../../../interfaces/domain/IPerson";
import { IHistory } from "../../../interfaces/domain/IHistory";
import { toast } from "react-toastify";

interface EntityMediaReferencesListProps {
  entityReferences: Array<IEntityReference<IPrisonCamp | IPerson | IHistory>>;
  entityType: IEntityReferenceTypeEnum;
  entityId: number;
}

const EntityMediaReferencesList: React.FunctionComponent<EntityMediaReferencesListProps> = (props) => {
  const [mediaReferences, setMediaReferences] = useState<IEntityReference[]>([]);
  const [show, setShow] = useState<{ [key: number]: boolean }>({});
  const targetRefs = useRef<{ [key: string]: HTMLTableCellElement | null }>({});
  const [fetchReference, { data, error }] = useLazyQuery(GET_REFERENCE_QUERY);

  useEffect(() => {
    if (props.entityReferences) {
      const filteredReferences = props.entityReferences.filter(
        (entityReference) =>
          entityReference.reference.category === "SOUND" ||
          entityReference.reference.category === "VIDEO" ||
          entityReference.reference.category === "IMAGE"
      );

      filteredReferences.forEach((entityReference) => {
        fetchReference({
          variables: { filter: { id: entityReference.reference.id } },
        }).then((result) => {
          if (result.data) {
            setMediaReferences((prevArray) => [...prevArray, result.data]);
          }
        });
      });
    }
  }, [props.entityReferences, fetchReference]);

  if (!props.entityReferences || props.entityReferences.length === 0) {
    return <DataNotFound></DataNotFound>;
  }

  const handleCopy = (ref: IReference) => {
    const textToCopy = ref.category === "IMAGE" ? ref.attachments[0]?.fileDownloadPath : ref.url;
    if (textToCopy === undefined || "") {
      toast.error("Kunne ikke finne en URL for denne mediereferansen")
    } else {
      navigator.clipboard.writeText(textToCopy).then(() => {
        setShow((prevShow) => ({ ...prevShow, [ref.id]: true }));
        setTimeout(() => setShow((prevShow) => ({ ...prevShow, [ref.id]: false })), 2000);
      });
    }
  };

  return (
    <div className="EntityReferencesList">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Tittel</th>
            <th scope="col">Kildens referansenavn</th>
            <th scope="col">Kategori</th>
            <th scope="col">Handling</th>
          </tr>
        </thead>
        <tbody>
          {mediaReferences && mediaReferences.map((entityReference, idx) => (
            <tr key={entityReference.id + idx.toString()}>
              <td>{entityReference.reference.id}</td>
              <td>
                <Link to={GET_REFERENCE_ROUTE(entityReference.reference.id)}>{entityReference.reference.title}</Link>
              </td>
              <td>{entityReference.reference.source}</td>
              <td>{GetReadableReferenceCategoryName(entityReference.reference.category)}</td>
              <td
                ref={(el) => (targetRefs.current[entityReference.reference.id] = el)}
                onClick={() => handleCopy(entityReference.reference)}
                style={{ cursor: "pointer" }}
                className="copy-reference"
              >
                Kopier {" "}
                <FaCopy />
                <Overlay target={targetRefs.current[entityReference.reference.id]} show={show[entityReference.reference.id]} placement="bottom">
                  {(props) => (
                    <Tooltip id={`tooltip-${entityReference.reference.id}`} {...props}>
                      Referanse-URL er kopiert til utklippstavlen
                    </Tooltip>
                  )}
                </Overlay>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EntityMediaReferencesList;