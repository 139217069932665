import {useMutation} from "@apollo/client";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {Prompt} from "react-router-dom";
import Select from "react-select";
import {UPDATE_PERSON_MUTATION} from "../../../../graphql/mutations";
import {GET_PERSON_QUERY} from "../../../../graphql/queries";
import {GetCivilStatusEnumAsSelectOptions, GetGenderEnumAsSelectOptions, GetReadableCivilStatusName, GetReadableGenderName,} from "../../../../helpers/enumHelpers";
import {handleMutationError, handleMutationSuccess, mutationIsSuccessful} from "../../../../helpers/formHelpers";
import {mapLocationFormToLocationMutationInput, mapLocationToLocationFormInput} from "../../../../helpers/locationHelpers";
import {mapAttachmentToSelectOption, mapToSelectOption} from "../../../../helpers/selectHelpers";
import {personSchema} from "../../../../helpers/validators/personSchema";
import {ICivilStatusEnum} from "../../../../interfaces/domain/enums/ICivilStatusEnum";
import {IGenderEnum} from "../../../../interfaces/domain/enums/IGenderEnum";
import {ICitizenship} from "../../../../interfaces/domain/ICitizenship";
import {IHistoryOption} from "../../../../interfaces/domain/IHistory";
import {INationality} from "../../../../interfaces/domain/INationality";
import {IPerson} from "../../../../interfaces/domain/IPerson";
import {IProfession} from "../../../../interfaces/domain/IProfession";
import {IRole} from "../../../../interfaces/domain/IRole";
import {IPersonForm} from "../../../../interfaces/forms/IPersonForm";
import {IUpdatePersonMutationInput, IUpdatePersonMutationResult} from "../../../../interfaces/mutations/IUpdatePersonMutation";
import CitizenshipsSelectInput from "../../../Citizenship/CitizenshipsSelectInput/CitizenshipsSelectInput";
import HistoriesSelectInput from "../../../History/HistoriesSelectInput/HistoriesSelectInput";
import NationalitiesSelectInput from "../../../Nationality/NationalitiesSelectInput/NationalitiesSelectInput";
import ProfessionsSelectInput from "../../../Profession/ProfessionsSelectInput/ProfessionsSelectInput";
import RolesSelectInput from "../../../Role/RolesSelectInput/RolesSelectInput";
import DateFields from "../../../_common/Form/DateFields/DateFields";
import FieldError from "../../../_common/Form/FieldError/FieldError";
import FormSubTitle from "../../../_common/Form/FormSubTitle/FormSubTitle";
import LocationAddressArea from "../../../_common/Form/LocationFields/LocationAddressArea";
import LocationFields from "../../../_common/Form/LocationFields/LocationFields";
import WYSIWYGEditor from "../../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";
import AliasFields from "../../Form/AliasFields/AliasFields";
import AuditLogEntityList from "../../../_common/Audit/AuditLog/AuditLogEntityList";
import {IEntityEnum} from "../../../../interfaces/domain/enums/IEntityEnum";
import {IEntityReferenceTypeEnum} from "../../../../interfaces/forms/IEntityReferenceForm";
import EntityMediaReferencesList from "../../../Reference/EntityReferences/EntityMediaReferencesList";

interface IEditPersonFormProps {
  person: IPerson;
}

const EditPersonForm: React.FunctionComponent<IEditPersonFormProps> = (props) => {
  const {register, setValue, getValues, handleSubmit, errors, formState, control} = useForm<IPersonForm>({
    validationSchema: personSchema,
    mode: "onChange",
    defaultValues: {
      identificationNumber: props.person.identificationNumber,
      krigsseilerRegisterId: props.person.krigsseilerRegisterId,
      krigsgraverRegisterId: props.person.krigsgraverRegisterId,
      soldatregisterId: props.person.soldatregisterId,
      firstName: props.person.firstName,
      middleName: props.person.middleName,
      lastName: props.person.lastName,
      aliases: props.person.aliases,
      birthDate: props.person.birthDate,
      deathDate: props.person.deathDate,
      remarks: {
        internalRemark: props.person.remarks.internalRemark,
        publicRemark: props.person.remarks.publicRemark,
      },
      gender: mapToSelectOption(props.person.gender, GetReadableGenderName(props.person.gender)),
      civilStatus: mapToSelectOption(props.person.civilStatus, GetReadableCivilStatusName(props.person.civilStatus)),
      professions: props.person.professions.map((profession) => {
        return mapToSelectOption(profession.id, profession.name);
      }),
      nationalities: props.person.nationalities.map((nationality) => {
        return mapToSelectOption(nationality.id, nationality.name);
      }),
      citizenships: props.person.citizenships.map((citizenship) => {
        return mapToSelectOption(citizenship.id, citizenship.name);
      }),
      roles: props.person.roles.map((role) => {
        return mapToSelectOption(role.id, role.name);
      }),
      histories: props.person.histories.map((history) => {
        return mapToSelectOption(history.id, history.title);
      }),
      mainImage: props.person.mainImage ? mapAttachmentToSelectOption(props.person.mainImage) : undefined,
      placeOfBirth1947: mapLocationToLocationFormInput(props.person.placeOfBirth1947),
      placeOfBirth2020: mapLocationToLocationFormInput(props.person.placeOfBirth2020),
      placeOfResidence1947: mapLocationToLocationFormInput(props.person.placeOfResidence1947),
      placeOfResidence2020: mapLocationToLocationFormInput(props.person.placeOfResidence2020),
      placeOfDeath1947: mapLocationToLocationFormInput(props.person.placeOfDeath1947),
      placeOfDeath2020: mapLocationToLocationFormInput(props.person.placeOfDeath2020),
      placeOfBirthAddressArea: {
        address: props.person.placeOfBirthAddress ? props.person.placeOfBirthAddress : undefined,
        area: props.person.placeOfBirthArea ? props.person.placeOfBirthArea : undefined,
      },
      placeOfResidenceAddressArea: {
        address: props.person.placeOfResidenceAddress ? props.person.placeOfResidenceAddress : undefined,
        area: props.person.placeOfResidenceArea ? props.person.placeOfResidenceArea : undefined,
      },
      placeOfDeathAddressArea: {
        address: props.person.placeOfDeathAddress ? props.person.placeOfDeathAddress : undefined,
        area: props.person.placeOfDeathArea ? props.person.placeOfDeathArea : undefined,
      },
      temporaryPlaceOfResidence: props.person.temporaryPlaceOfResidence,
    },
  });

  const [updatePerson] = useMutation<IUpdatePersonMutationResult, IUpdatePersonMutationInput>(UPDATE_PERSON_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updatePerson({
      variables: {
        input: {
          id: props.person.id,
          identificationNumber: formValues.identificationNumber,
          krigsseilerRegisterId: formValues.krigsseilerRegisterId,
          krigsgraverRegisterId: formValues.krigsgraverRegisterId,
          soldatregisterId: formValues.soldatregisterId,
          firstName: formValues.firstName,
          middleName: formValues.middleName,
          lastName: formValues.lastName,
          birthDate: formValues.birthDate,
          deathDate: formValues.deathDate,
          remarks: {
            internalRemark: formValues.remarks.internalRemark,
            publicRemark: formValues.remarks.publicRemark,
          },
          gender: formValues.gender?.value as IGenderEnum,
          civilStatus: formValues.civilStatus?.value as ICivilStatusEnum,
          aliases: formValues.aliases,
          professions: formValues.professions?.map((profession) => {
            return {id: profession.value, name: profession.label} as IProfession;
          }),
          nationalities: formValues.nationalities?.map((nationality) => {
            return {id: nationality.value, name: nationality.label} as INationality;
          }),
          citizenships: formValues.citizenships?.map((citizenship) => {
            return {id: citizenship.value, name: citizenship.label} as ICitizenship;
          }),
          roles: formValues.roles?.map((role) => {
            return {id: role.value, name: role.label} as IRole;
          }),
          mainImageId: formValues.mainImage && formValues.mainImage.value ? Number(formValues.mainImage.value) : undefined,
          placeOfBirth1947: mapLocationFormToLocationMutationInput(formValues.placeOfBirth1947),
          placeOfBirth2020: mapLocationFormToLocationMutationInput(formValues.placeOfBirth2020),
          placeOfResidence1947: mapLocationFormToLocationMutationInput(formValues.placeOfResidence1947),
          placeOfResidence2020: mapLocationFormToLocationMutationInput(formValues.placeOfResidence2020),
          placeOfDeath1947: mapLocationFormToLocationMutationInput(formValues.placeOfDeath1947),
          placeOfDeath2020: mapLocationFormToLocationMutationInput(formValues.placeOfDeath2020),
          placeOfBirthAddressArea: {
            address: formValues.placeOfBirthAddressArea.address ? formValues.placeOfBirthAddressArea.address : undefined,
            area: formValues.placeOfBirthAddressArea.area ? formValues.placeOfBirthAddressArea.area : undefined,
          },
          placeOfResidenceAddressArea: {
            address: formValues.placeOfResidenceAddressArea.address ? formValues.placeOfResidenceAddressArea.address : undefined,
            area: formValues.placeOfResidenceAddressArea.area ? formValues.placeOfResidenceAddressArea.area : undefined,
          },
          placeOfDeathAddressArea: {
            address: formValues.placeOfDeathAddressArea.address ? formValues.placeOfDeathAddressArea.address : undefined,
            area: formValues.placeOfDeathAddressArea.area ? formValues.placeOfDeathAddressArea.area : undefined,
          },
          temporaryPlaceOfResidence: formValues.temporaryPlaceOfResidence,
          histories: formValues.histories?.map((history) => {
            return {id: history.value, title: history.label} as IHistoryOption;
          }),
        },
      },
      refetchQueries: [{query: GET_PERSON_QUERY, variables: {filter: {id: props.person.id}}}],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updatePerson)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Prompt
        when={formState.dirty && !formState.isSubmitted}
        message="Du har endringer som ikke er lagret. Er du sikker på du vil fortsette? Endringene vil gå tapt hvis du fortsetter uten å lagre.
        Trykk OK for å gå videre uten å lagre, eller trykk Avbryt for å gå tilbake."
      />

      <div className="row">
        <div className="col-lg-7">
          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Primærnavn</FormSubTitle>

              <div className="form-row">
                <div className="col form-group">
                  <label htmlFor="firstName">Fornavn</label>

                  <input
                    type="text"
                    name="firstName"
                    ref={register}
                    className={`form-control ${errors.firstName ? "is-invalid" : "valid"}`}
                  />

                  <FieldError error={errors.firstName}></FieldError>
                </div>

                <div className="col form-group">
                  <label htmlFor="middleName">Mellomnavn</label>

                  <input
                    type="text"
                    name="middleName"
                    ref={register}
                    className={`form-control ${errors.middleName ? "is-invalid" : "valid"}`}
                  />

                  <FieldError error={errors.middleName}></FieldError>
                </div>

                <div className="col form-group">
                  <label htmlFor="lastName">Etternavn</label>

                  <input
                    type="text"
                    name="lastName"
                    ref={register}
                    className={`form-control ${errors.lastName ? "is-invalid" : "valid"}`}
                  />

                  <FieldError error={errors.lastName}></FieldError>
                </div>
              </div>

              <div className="form-group">
                <FormSubTitle>Tilleggsnavn</FormSubTitle>

                <AliasFields fieldName="aliases" control={control} register={register} errors={errors}></AliasFields>
              </div>
            </div>
          </div>

          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Født</FormSubTitle>

              <label htmlFor="birthDate">Dato</label>
              <DateFields fieldName="birthDate" register={register} errors={errors.birthDate}></DateFields>

              <LocationAddressArea
                register={register}
                errors={errors}
                setValue={setValue}
                fieldName="placeOfBirthAddressArea"></LocationAddressArea>

              <h6>1947</h6>

              <LocationFields
                fieldName="placeOfBirth1947"
                control={control}
                errors={errors}
                currentValues={getValues().placeOfBirth1947}
                onChange={(value) => {
                  setValue("placeOfBirth1947", value, true);
                }}
                yearOfOrigin={1947}></LocationFields>

              <h6>2020</h6>

              <LocationFields
                fieldName="placeOfBirth2020"
                control={control}
                errors={errors}
                currentValues={getValues().placeOfBirth2020}
                onChange={(value) => {
                  setValue("placeOfBirth2020", value, true);
                }}
                yearOfOrigin={2020}></LocationFields>
            </div>
          </div>

          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Død</FormSubTitle>

              <label htmlFor="deathDate">Dato</label>
              <DateFields fieldName="deathDate" register={register} errors={errors.deathDate}></DateFields>

              <LocationAddressArea
                register={register}
                errors={errors}
                setValue={setValue}
                fieldName="placeOfDeathAddressArea"></LocationAddressArea>

              <h6>1947</h6>

              <LocationFields
                fieldName="placeOfDeath1947"
                control={control}
                errors={errors}
                currentValues={getValues().placeOfDeath1947}
                onChange={(value) => {
                  setValue("placeOfDeath1947", value, true);
                }}
                yearOfOrigin={1947}></LocationFields>

              <h6>2020</h6>

              <LocationFields
                fieldName="placeOfDeath2020"
                control={control}
                errors={errors}
                currentValues={getValues().placeOfDeath2020}
                onChange={(value) => {
                  setValue("placeOfDeath2020", value, true);
                }}
                yearOfOrigin={2020}></LocationFields>
            </div>
          </div>

          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Bosted</FormSubTitle>

              <LocationAddressArea
                register={register}
                errors={errors}
                setValue={setValue}
                fieldName="placeOfResidenceAddressArea"></LocationAddressArea>

              <h6>1947</h6>

              <LocationFields
                fieldName="placeOfResidence1947"
                control={control}
                errors={errors}
                currentValues={getValues().placeOfResidence1947}
                onChange={(value) => {
                  setValue("placeOfResidence1947", value, true);
                }}
                yearOfOrigin={1947}></LocationFields>

              <h6>2020</h6>

              <LocationFields
                fieldName="placeOfResidence2020"
                control={control}
                errors={errors}
                currentValues={getValues().placeOfResidence2020}
                onChange={(value) => {
                  setValue("placeOfResidence2020", value, true);
                }}
                yearOfOrigin={2020}></LocationFields>

              <label>Notat om midlertidig bosted</label>

              <textarea
                name="temporaryPlaceOfResidence"
                ref={register}
                className={`form-control ${errors.temporaryPlaceOfResidence ? "is-invalid" : "valid"}`}
                rows={3}
              />
            </div>
          </div>
          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Lyd / Video referanser</FormSubTitle>
              <div className="form-group">
                <p>Her finner du referanser av typen lyd, video eller bilde. Kopier URL-en til referansen og lim den inn under bildeikonet for å vise et bilde, eller under videoikonet for å vise en video eller et lydklipp.</p>
              </div>
              <EntityMediaReferencesList
                entityReferences={props.person.references}
                entityType={IEntityReferenceTypeEnum.PERSON}
                entityId={props.person.id}/>
            </div>
          </div>
        </div>

        <div className="col-lg-5">
          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Hovedbilde</FormSubTitle>

              <div className="form-row">
                <div className="col-md-12">
                  {props.person.mainImage || props.person.availableImageAttachments.length > 0 ? (
                    <div className="form-group">
                      <Controller
                        as={Select}
                        control={control}
                        name="mainImage"
                        value={getValues().mainImage}
                        options={props.person.availableImageAttachments.map((attachment) => mapAttachmentToSelectOption(attachment))}
                        placeholder="Velg..."
                        isClearable
                      />
                    </div>
                  ) : (
                    <span className="text-muted">Du må først legge til en referanse med et bildevedlegg.</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="card shadow-sm mb-3">
            <div className="card-body">
              {props.person.identificationNumber && (
                <div className="form-group">
                  <label htmlFor="identificationNumber">Identifikasjonsnummer</label>

                  <input
                    disabled={true}
                    type="text"
                    name="identificationNumber"
                    ref={register}
                    className={`form-control ${errors.identificationNumber ? "is-invalid" : "valid"}`}
                  />

                  <FieldError error={errors.identificationNumber}></FieldError>
                </div>
              )}

              <div className="form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="gender">Kjønn</label>

                    <Controller
                      as={Select}
                      control={control}
                      name="gender"
                      value={getValues().gender}
                      options={GetGenderEnumAsSelectOptions()}
                      placeholder="Velg..."
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="civilStatus">Sivilstatus</label>

                    <Controller
                      as={Select}
                      control={control}
                      name="civilStatus"
                      value={getValues().civilStatus}
                      options={GetCivilStatusEnumAsSelectOptions()}
                      placeholder="Velg..."
                    />
                  </div>
                </div>
              </div>

              <ProfessionsSelectInput
                fieldName="professions"
                label="Yrker"
                control={control}
                errors={errors}
                currentValues={getValues().professions}
              />

              <NationalitiesSelectInput
                fieldName="nationalities"
                label="Landtilhørighet"
                control={control}
                errors={errors}
                currentValues={getValues().nationalities}
              />

              <CitizenshipsSelectInput
                fieldName="citizenships"
                label="Statsborgerskap"
                control={control}
                errors={errors}
                currentValues={getValues().citizenships}
              />

              <RolesSelectInput fieldName="roles" label="Roller" control={control} errors={errors} currentValues={getValues().roles}/>

              <div className="form-group">
                <label htmlFor="krigsseilerRegisterId">ID fra Krigsseilerregisteret.no</label>
                <input
                  type="text"
                  name="krigsseilerRegisterId"
                  ref={register}
                  className={`form-control ${errors.krigsseilerRegisterId ? "is-invalid" : "valid"}`}
                />

                <FieldError error={errors.krigsseilerRegisterId}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="krigsgraverRegisterId">ID fra Krigsgraver.no</label>
                <input
                  type="text"
                  name="krigsgraverRegisterId"
                  ref={register}
                  className={`form-control ${errors.krigsgraverRegisterId ? "is-invalid" : "valid"}`}
                />

                <FieldError error={errors.krigsgraverRegisterId}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="soldatregisterId">Soldatregister Id</label>
                <input
                  type="text"
                  name="soldatregisterId"
                  ref={register}
                  className={`form-control ${errors.soldatregisterId ? "is-invalid" : "valid"}`}
                />

                <FieldError error={errors.soldatregisterId}></FieldError>
              </div>
            </div>
          </div>

          <div className="card shadow-sm">
            <div className="card-body">
              <FormSubTitle>Tilknyttede historier</FormSubTitle>

              <HistoriesSelectInput fieldName="histories" control={control} errors={errors} currentValues={getValues().histories}/>
            </div>
          </div>

          <div className="card shadow-sm mt-3">
            <div className="card-body">
              <FormSubTitle>Notater</FormSubTitle>

              <label>Notat (vises kun internt)</label>

              <WYSIWYGEditor
                fieldName="remarks.internalRemark"
                register={register}
                errors={errors}
                setValue={setValue}
                currentValue={getValues({nest: true}).remarks.internalRemark}
                initialValue={props.person.remarks.internalRemark || ""}
              />

              <label>Notat (vil bli offentliggjort)</label>

              <WYSIWYGEditor
                fieldName="remarks.publicRemark"
                register={register}
                errors={errors}
                setValue={setValue}
                currentValue={getValues({nest: true}).remarks.publicRemark}
                initialValue={props.person.remarks.publicRemark || ""}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card shadow-sm mt-3 mb-3">
        <div className="card-body">
          <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
            {formState.isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span> Lagrer...</span>
              </>
            ) : (
              <span>Lagre</span>
            )}
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className="card shadow-sm mt-3 mb-3">
            <div className="card-body">
              <AuditLogEntityList entityId={props.person.id} entityName={IEntityEnum.PERSONS}/>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditPersonForm;
