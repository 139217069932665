import { useMutation } from "@apollo/client";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Prompt, useHistory } from "react-router-dom";
import Select from "react-select";
import { CREATE_REFERENCE_MUTATION } from "../../../graphql/mutations";
import { GetReferenceCategoryEnumAsSelectOptions } from "../../../helpers/enumHelpers";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { referenceSchema } from "../../../helpers/validators/referenceSchema";
import { IReferenceCategoryEnum } from "../../../interfaces/domain/enums/IReferenceCategoryEnum";
import { IReferenceForm } from "../../../interfaces/forms/IReferenceForm";
import { ICreateReferenceMutationInput, ICreateReferenceMutationResult } from "../../../interfaces/mutations/ICreateReferenceMutation";
import { GET_REFERENCE_ROUTE } from "../../../lib/Router/routes";
import FieldError from "../../_common/Form/FieldError/FieldError";
import WYSIWYGEditor from "../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";

const CreateReferenceForm: React.FunctionComponent = () => {
  const history = useHistory();

  const { register, watch, handleSubmit, errors, formState, getValues, control, setValue } = useForm<IReferenceForm>({
    validationSchema: referenceSchema,
    mode: "onChange",
  });
  const watchCategory = watch("category");
  const [createReference] = useMutation<ICreateReferenceMutationResult, ICreateReferenceMutationInput>(CREATE_REFERENCE_MUTATION);

  React.useEffect(() => {
    if (watchCategory?.value !== IReferenceCategoryEnum.VIDEO && watchCategory?.value !== IReferenceCategoryEnum.SOUND){
      setValue("showEmbedded", false);
    }
  },[watchCategory, setValue])

  const onSubmit = handleSubmit(async (formValues) => {
    await createReference({
      variables: {
        input: {
          title: formValues.title,
          archiveIdentification: formValues.archiveIdentification,
          source: formValues.source,
          url: formValues.url,
          metadataUrl: formValues.metadataUrl,
          description: formValues.description,
          internalRemark: formValues.internalRemark,
          category: formValues.category.value as IReferenceCategoryEnum,
          showEmbedded: formValues.showEmbedded,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createReference)) {
          handleMutationSuccess(result.data);

          history.push(GET_REFERENCE_ROUTE(result.data.createReference.id));
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Prompt
        when={formState.dirty && !formState.isSubmitting}
        message="Du har endringer som ikke er lagret. Er du sikker på du vil fortsette? Endringene vil gå tapt hvis du fortsetter uten å lagre.
        Trykk OK for å gå videre uten å lagre, eller trykk Avbryt for å gå tilbake."
      />
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="form-group">
            <label className="required-field" htmlFor="category">
              Kategori
            </label>

            <Controller
              as={Select}
              control={control}
              name="category"
              value={getValues().category}
              options={GetReferenceCategoryEnumAsSelectOptions()}
              placeholder="Velg..."
            />
          </div>
          {(watchCategory?.value === IReferenceCategoryEnum.VIDEO || watchCategory?.value === IReferenceCategoryEnum.SOUND) &&
            <div className="form-group">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  placeholder="showEmbedded"
                  id="showEmbedded"
                  name="showEmbedded"
                  ref={register}
                />
                <label className="form-check-label" htmlFor="showEmbedded">
                  Vise avspiller? (Gjelder kun Digitalarkivet, Youtube, Vimeo)
                </label>
                <small className="form-text text-muted">
                  For å vise avspiller må Nettadresse (URL) være i et av følgende format: 'https://media.digitalarkivet.no/media/', 'https://youtu.be/', 'https://www.youtube.com/watch?', 'https://vimeo.com/'
                </small>
              </div>
            </div>
          }

          <div className="form-group">
            <label htmlFor="source">Arkivskaper og -institusjon / Forfatter og utgiver / Fotograf</label>
            <input type="text" name="source" ref={register} className={`form-control ${errors.source ? "is-invalid" : "valid"}`} />

            <FieldError error={errors.source}></FieldError>
          </div>

          <div className="form-group">
            <label className="required-field" htmlFor="title">
              Tittel
            </label>
            <input type="text" name="title" ref={register} className={`form-control ${errors.title ? "is-invalid" : "valid"}`} />

            <FieldError error={errors.title}></FieldError>
          </div>

          <WYSIWYGEditor
            fieldName="description"
            label="Beskrivelse"
            register={register}
            errors={errors}
            setValue={setValue}
            currentValue={getValues().description}
            size="small"
          />

          <div className="form-group">
            <label htmlFor="archiveIdentification">Arkivreferanse / ISBN</label>
            <input
              type="text"
              name="archiveIdentification"
              ref={register}
              className={`form-control ${errors.archiveIdentification ? "is-invalid" : "valid"}`}
            />

            <FieldError error={errors.archiveIdentification}></FieldError>
          </div>

          <div className="form-group">
            <label htmlFor="url">Nettadresse (URL)</label>
            <input type="text" name="url" ref={register} className={`form-control ${errors.url ? "is-invalid" : "valid"}`} />

            <FieldError error={errors.url}></FieldError>
          </div>

          <div className="form-group">
            <label htmlFor="metadataUrl">Metadata URL (video/lyd)</label>
            <input
              type="text"
              name="metadataUrl"
              ref={register}
              className={`form-control ${errors.metadataUrl ? "is-invalid" : "valid"}`}
            />

            <FieldError error={errors.metadataUrl}></FieldError>
          </div>

          <WYSIWYGEditor
            fieldName="internalRemark"
            label="Notat (vises kun internt)"
            register={register}
            errors={errors}
            setValue={setValue}
            currentValue={getValues().internalRemark}
            size="small"
          />
        </div>

        <div className="card-footer">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
              {formState.isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span> Oppretter...</span>
                </>
              ) : (
                <span>Opprett</span>
              )}
            </button>

            <div>
              <small className="text-muted">Du kan laste opp vedlegg etter referansen er opprettet</small>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateReferenceForm;
