import * as yup from "yup";
import { dateSchema } from "./dateSchema";

export const historySchema = yup.object({
  title: yup.string().required("Må fylles ut"),
  startDate: dateSchema,
  endDate: dateSchema,
  slug: yup
    .string()
    .required()
    .matches(/^[a-z0-9-]+$/, "Kan kun inneholde bokstavene a-z, tall og bindestrek. Mellomrom er ikke tilltatt"),
});
