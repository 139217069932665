import {IAuthenticatedUser, JwtToken, TokenClaims} from "../interfaces/domain/IUser";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

const infoCookieName = "fanger_info";

export const getRolesFromDecodedToken = (decodedToken: JwtToken) => {
  const tokenRoles = decodedToken[TokenClaims.ROLE];

  if (tokenRoles) {
    if (Array.isArray(tokenRoles)) {
      return tokenRoles;
    } else {
      return [tokenRoles];
    }
  }

  return [];
};

export const decodeJwtToken = (token: string) => {
  return jwt_decode<JwtToken>(token);
};

export const getUserJwtFromCookie = () => {
  return Cookies.get(infoCookieName);
};

export const getAuthenticatedUserFromInfoCookie = () => {
  const jwt = getUserJwtFromCookie();

  if (jwt) {
    const decodedToken = decodeJwtToken(jwt);


    const authenticatedUser: IAuthenticatedUser = {
      id: decodedToken.jti,
      email: decodedToken.sub,
      userName: decodedToken.sub,
      fullName: decodedToken[TokenClaims.NAME],
      roles: getRolesFromDecodedToken(decodedToken),
      expiry: decodedToken.exp
    };

    return authenticatedUser;
  }

  return undefined;
};

export const deleteInfoCookie = () => {
  Cookies.remove(infoCookieName);
};
