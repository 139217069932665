import { loader } from "graphql.macro";

export const SIGN_IN_MUTATION = loader("./SIGN_IN_MUTATION.gql");
export const SIGN_OUT_MUTATION = loader("./SIGN_OUT_MUTATION.gql");
export const CHANGE_PASSWORD_MUTATION = loader("./CHANGE_PASSWORD_MUTATION.gql");
export const RESET_PASSWORD_MUTATION = loader("./RESET_PASSWORD_MUTATION.gql");
export const GENERATE_RESET_PASSWORD_TOKEN_MUTATION = loader("./GENERATE_RESET_PASSWORD_TOKEN_MUTATION.gql");

export const CREATE_USER_MUTATION = loader("./CREATE_USER_MUTATION.gql");
export const UPDATE_USER_MUTATION = loader("./UPDATE_USER_MUTATION.gql");
export const DELETE_USER_MUTATION = loader("./DELETE_USER_MUTATION.gql");
export const ASSIGN_USER_ROLE_MUTATION = loader("./ASSIGN_USER_ROLE_MUTATION.gql");

export const CREATE_PERSON_MUTATION = loader("./CREATE_PERSON_MUTATION.gql");
export const UPDATE_PERSON_MUTATION = loader("./UPDATE_PERSON_MUTATION.gql");
export const DELETE_PERSON_MUTATION = loader("./DELETE_PERSON_MUTATION.gql");
export const TOGGLE_PUBLISH_PERSON_MUTATION = loader("./TOGGLE_PUBLISH_PERSON_MUTATION.gql");

export const CREATE_PROFESSION_MUTATION = loader("./CREATE_PROFESSION_MUTATION.gql");
export const UPDATE_PROFESSION_MUTATION = loader("./UPDATE_PROFESSION_MUTATION.gql");
export const DELETE_PROFESSION_MUTATION = loader("./DELETE_PROFESSION_MUTATION.gql");

export const CREATE_INDUSTRY_MUTATION = loader("./CREATE_INDUSTRY_MUTATION.gql");
export const UPDATE_INDUSTRY_MUTATION = loader("./UPDATE_INDUSTRY_MUTATION.gql");
export const DELETE_INDUSTRY_MUTATION = loader("./DELETE_INDUSTRY_MUTATION.gql");

export const CREATE_CITIZENSHIP_MUTATION = loader("./CREATE_CITIZENSHIP_MUTATION.gql");
export const UPDATE_CITIZENSHIP_MUTATION = loader("./UPDATE_CITIZENSHIP_MUTATION.gql");

export const CREATE_EVENT_TYPE_MUTATION = loader("./CREATE_EVENT_TYPE_MUTATION.gql");
export const UPDATE_EVENT_TYPE_MUTATION = loader("./UPDATE_EVENT_TYPE_MUTATION.gql");

export const CREATE_NATIONALITY_MUTATION = loader("./CREATE_NATIONALITY_MUTATION.gql");
export const UPDATE_NATIONALITY_MUTATION = loader("./UPDATE_NATIONALITY_MUTATION.gql");

export const CREATE_ROLE_MUTATION = loader("./CREATE_ROLE_MUTATION.gql");
export const UPDATE_ROLE_MUTATION = loader("./UPDATE_ROLE_MUTATION.gql");

export const CREATE_PRISON_CAMP_CATEGORY_MUTATION = loader("./CREATE_PRISON_CAMP_CATEGORY_MUTATION.gql");
export const UPDATE_PRISON_CAMP_CATEGORY_MUTATION = loader("./UPDATE_PRISON_CAMP_CATEGORY_MUTATION.gql");
export const CREATE_COUNTRY_MUTATION = loader("./CREATE_COUNTRY_MUTATION.gql");
export const UPDATE_COUNTRY_MUTATION = loader("./UPDATE_COUNTRY_MUTATION.gql");

export const CREATE_REGION_MUTATION = loader("./CREATE_REGION_MUTATION.gql");
export const UPDATE_REGION_MUTATION = loader("./UPDATE_REGION_MUTATION.gql");

export const CREATE_DISTRICT_MUTATION = loader("./CREATE_DISTRICT_MUTATION.gql");
export const UPDATE_DISTRICT_MUTATION = loader("./UPDATE_DISTRICT_MUTATION.gql");

export const CREATE_HISTORY_MUTATION = loader("./CREATE_HISTORY_MUTATION.gql");
export const UPDATE_HISTORY_MUTATION = loader("./UPDATE_HISTORY_MUTATION.gql");
export const DELETE_HISTORY_MUTATION = loader("./DELETE_HISTORY_MUTATION.gql");
export const TOGGLE_PUBLISH_HISTORY_MUTATION = loader("./TOGGLE_PUBLISH_HISTORY_MUTATION.gql");

export const CREATE_PRISON_CAMP_MUTATION = loader("./CREATE_PRISON_CAMP_MUTATION.gql");
export const UPDATE_PRISON_CAMP_MUTATION = loader("./UPDATE_PRISON_CAMP_MUTATION.gql");
export const DELETE_PRISON_CAMP_MUTATION = loader("./DELETE_PRISON_CAMP_MUTATION.gql");

export const CREATE_CAPTIVITY_MUTATION = loader("./CREATE_CAPTIVITY_MUTATION.gql");
export const UPDATE_CAPTIVITY_MUTATION = loader("./UPDATE_CAPTIVITY_MUTATION.gql");
export const DELETE_CAPTIVITY_MUTATION = loader("./DELETE_CAPTIVITY_MUTATION.gql");

export const CREATE_EVENT_MUTATION = loader("./CREATE_EVENT_MUTATION.gql");
export const UPDATE_EVENT_MUTATION = loader("./UPDATE_EVENT_MUTATION.gql");
export const DELETE_EVENT_MUTATION = loader("./DELETE_EVENT_MUTATION.gql");

export const CREATE_ARTICLE_MUTATION = loader("./CREATE_ARTICLE_MUTATION.gql");
export const UPDATE_ARTICLE_MUTATION = loader("./UPDATE_ARTICLE_MUTATION.gql");
export const DELETE_ARTICLE_MUTATION = loader("./DELETE_ARTICLE_MUTATION.gql");

export const CREATE_REFERENCE_MUTATION = loader("./CREATE_REFERENCE_MUTATION.gql");
export const UPDATE_REFERENCE_MUTATION = loader("./UPDATE_REFERENCE_MUTATION.gql");
export const DELETE_REFERENCE_MUTATION = loader("./DELETE_REFERENCE_MUTATION.gql");

export const CREATE_ATTACHMENT_MUTATION = loader("./CREATE_ATTACHMENT_MUTATION.gql");
export const UPDATE_ATTACHMENT_MUTATION = loader("./UPDATE_ATTACHMENT_MUTATION.gql");
export const DELETE_ATTACHMENT_MUTATION = loader("./DELETE_ATTACHMENT_MUTATION.gql");

export const ATTACH_ENTITY_REFERENCE_MUTATION = loader("./ATTACH_ENTITY_REFERENCE_MUTATION.gql");
export const UPDATE_ENTITY_REFERENCE_MUTATION = loader("./UPDATE_ENTITY_REFERENCE_MUTATION.gql");
export const CREATE_ENTITY_REFERENCE_MUTATION = loader("./CREATE_ENTITY_REFERENCE_MUTATION.gql");
export const DELETE_ENTITY_REFERENCE_MUTATION = loader("./DELETE_ENTITY_REFERENCE_MUTATION.gql");

export const CREATE_TEACHING_MODULE_MUTATION = loader("./CREATE_TEACHING_MODULE_MUTATION.gql");
export const UPDATE_TEACHING_MODULE_MUTATION = loader("./UPDATE_TEACHING_MODULE_MUTATION.gql");
export const DELETE_TEACHING_MODULE_MUTATION = loader("./DELETE_TEACHING_MODULE_MUTATION.gql");

export const CREATE_TEACHING_CATEGORY_MUTATION = loader("./CREATE_TEACHING_CATEGORY_MUTATION.gql");
export const UPDATE_TEACHING_CATEGORY_MUTATION = loader("./UPDATE_TEACHING_CATEGORY_MUTATION.gql");
export const DELETE_TEACHING_CATEGORY_MUTATION = loader("./DELETE_TEACHING_CATEGORY_MUTATION.gql");
export const TOGGLE_PUBLISH_TEACHING_CATEGORY_MUTATION = loader("./TOGGLE_PUBLISH_TEACHING_CATEGORY_MUTATION.gql");

export const CREATE_TEACHING_SUBJECT_MUTATION = loader("./CREATE_TEACHING_SUBJECT_MUTATION.gql");
export const UPDATE_TEACHING_SUBJECT_MUTATION = loader("./UPDATE_TEACHING_SUBJECT_MUTATION.gql");
export const DELETE_TEACHING_SUBJECT_MUTATION = loader("./DELETE_TEACHING_SUBJECT_MUTATION.gql");
export const TOGGLE_PUBLISH_TEACHING_SUBJECT_MUTATION = loader("./TOGGLE_PUBLISH_TEACHING_SUBJECT_MUTATION.gql");

export const CREATE_ASSIGNMENT_MUTATION = loader("./CREATE_ASSIGNMENT_MUTATION.gql");
export const UPDATE_ASSIGNMENT_MUTATION = loader("./UPDATE_ASSIGNMENT_MUTATION.gql");
export const DELETE_ASSIGNMENT_MUTATION = loader("./DELETE_ASSIGNMENT_MUTATION.gql");
export const TOGGLE_PUBLISH_ASSIGNMENT_MUTATION = loader("./TOGGLE_PUBLISH_ASSIGNMENT_MUTATION.gql");

export const CREATE_QUESTION_MUTATION = loader("./CREATE_QUESTION_MUTATION.gql")
export const UPDATE_QUESTION_MUTATION = loader("./UPDATE_QUESTION_MUTATION.gql");
export const DELETE_QUESTION_MUTATION = loader("./DELETE_QUESTION_MUTATION.gql");
export const TOGGLE_PUBLISH_QUESTION_MUTATION = loader("./TOGGLE_PUBLISH_QUESTION_MUTATION.gql");

export const TOGGLE_ALGOLIA_AUTOMATIC_SYNC_SETTING_MUTATION = loader("./TOGGLE_ALGOLIA_AUTOMATIC_SYNC_SETTING_MUTATION.gql");
export const RUN_COMPLETE_ALGOLIA_RESYNC = loader("./RUN_COMPLETE_ALGOLIA_RESYNC.gql");

export const EXPORT_PERSON_TO_CSV_MUTATION = loader("./EXPORT_PERSON_TO_CSV_MUTATION.gql");

export const CREATE_HISTORY_HIERARCHY_MUTATION = loader("./CREATE_HISTORY_HIERARCHY_MUTATION.gql");
export const UPDATE_HISTORY_HIERARCHY_MUTATION = loader("./UPDATE_HISTORY_HIERARCHY_MUTATION.gql");
export const DELETE_HISTORY_HIERARCHY_MUTATION = loader("./DELETE_HISTORY_HIERARCHY_MUTATION.gql");