import { useMutation } from "@apollo/client";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { CREATE_ENTITY_REFERENCE_MUTATION } from "../../../../graphql/mutations";
import { getEntityQueryByEntityReferenceType } from "../../../../helpers/entityReferenceHelpers";
import { GetReferenceCategoryEnumAsSelectOptions } from "../../../../helpers/enumHelpers";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../../helpers/formHelpers";
import { createEntityReferenceSchema } from "../../../../helpers/validators/createEntityReferenceSchema";
import { IReferenceCategoryEnum } from "../../../../interfaces/domain/enums/IReferenceCategoryEnum";
import { ICreateEntityReferenceForm, IEntityReferenceTypeEnum } from "../../../../interfaces/forms/IEntityReferenceForm";
import {
  ICreateEntityReferenceMutationInput,
  ICreateEntityReferenceMutationResult,
} from "../../../../interfaces/mutations/ICreateEntityReferenceMutation";
import FieldError from "../../../_common/Form/FieldError/FieldError";
import FormSubTitle from "../../../_common/Form/FormSubTitle/FormSubTitle";
import WYSIWYGEditor from "../../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";

interface CreateEntityReferenceFormProps {
  entityType: IEntityReferenceTypeEnum;
  entityId: number;
  handleClose: () => void;
}

const CreateEntityReferenceForm: React.FunctionComponent<CreateEntityReferenceFormProps> = (props) => {
  const { register, watch, handleSubmit, errors, formState, getValues, control, setValue } = useForm<ICreateEntityReferenceForm>({
    validationSchema: createEntityReferenceSchema,
    mode: "onChange",
  });

  const watchCategory = watch("category");
  const [createEntityReference] = useMutation<ICreateEntityReferenceMutationResult, ICreateEntityReferenceMutationInput>(
    CREATE_ENTITY_REFERENCE_MUTATION
  );

  React.useEffect(() => {
    if (watchCategory?.value !== IReferenceCategoryEnum.VIDEO && watchCategory?.value !== IReferenceCategoryEnum.SOUND){
      setValue("showEmbedded", false);
    }
  },[watchCategory, setValue])

  const onSubmit = handleSubmit(async (formValues) => {
    await createEntityReference({
      variables: {
        input: {
          entityType: props.entityType,
          entityId: props.entityId,
          reference: {
            title: formValues.title,
            url: formValues.url,
            archiveIdentification: formValues.archiveIdentification,
            source: formValues.source,
            description: formValues.description,
            internalRemark: formValues.internalRemark,
            category: formValues.category.value as IReferenceCategoryEnum,
            showEmbedded: formValues.showEmbedded,
            metadataUrl: formValues.metadataUrl,
          },
          contextualComment: formValues.contextualComment,
          contextualUrl: formValues.contextualUrl,
        },
      },
      refetchQueries: [{ query: getEntityQueryByEntityReferenceType(props.entityType), variables: { filter: { id: props.entityId } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createEntityReference)) {
          handleMutationSuccess(result.data);

          props.handleClose();
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <FormSubTitle>Referansedetaljer</FormSubTitle>

      <div className="form-group">
        <label className="required-field" htmlFor="category">
          Kategori
        </label>

        <Controller
          as={Select}
          control={control}
          name="category"
          value={getValues().category}
          options={GetReferenceCategoryEnumAsSelectOptions()}
          placeholder="Velg..."
        />
      </div>
      {(watchCategory?.value === IReferenceCategoryEnum.VIDEO || watchCategory?.value === IReferenceCategoryEnum.SOUND) &&
        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              placeholder="showEmbedded"
              id="showEmbedded"
              name="showEmbedded"
              ref={register}
            />
            <label className="form-check-label" htmlFor="showEmbedded">
              Vise avspiller? (Gjelder kun Digitalarkivet, Youtube, Vimeo)
            </label>
            <small className="form-text text-muted">
              For å vise avspiller må Nettadresse (URL) være i et av følgende format: 'https://media.digitalarkivet.no/media/', 'https://youtu.be/', 'https://www.youtube.com/watch?', 'https://vimeo.com/'
            </small>
          </div>
        </div>
      }

      <div className="form-group">
        <label htmlFor="source">Arkivskaper og -institusjon / Forfatter og utgiver / Fotograf</label>
        <input type="text" name="source" ref={register} className={`form-control ${errors.source ? "is-invalid" : "valid"}`} />
        <FieldError error={errors.source}></FieldError>
      </div>

      <div className="form-group">
        <label className="required-field" htmlFor="title">
          Tittel
        </label>
        <input type="text" name="title" ref={register} className={`form-control ${errors.title ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.title}></FieldError>
      </div>

      <WYSIWYGEditor
        fieldName="description"
        label="Beskrivelse"
        register={register}
        errors={errors}
        setValue={setValue}
        currentValue={getValues().description}
        size="small"
      />

      <div className="form-group">
        <label htmlFor="archiveIdentification">Arkivreferanse / ISBN </label>
        <input
          type="text"
          name="archiveIdentification"
          ref={register}
          className={`form-control ${errors.archiveIdentification ? "is-invalid" : "valid"}`}
        />

        <FieldError error={errors.archiveIdentification}></FieldError>
      </div>

      <div className="form-group">
        <label htmlFor="url">Nettadresse (URL)</label>
        <input type="text" name="url" ref={register} className={`form-control ${errors.url ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.url}></FieldError>
      </div>

      <div className="form-group">
        <label htmlFor="metadataUrl">Metadata URL (video/lyd)</label>
        <input type="text" name="metadataUrl" ref={register} className={`form-control ${errors.metadataUrl ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.metadataUrl}></FieldError>
      </div>

      <WYSIWYGEditor
        fieldName="internalRemark"
        label="Notat (vises kun internt)"
        register={register}
        errors={errors}
        setValue={setValue}
        currentValue={getValues().internalRemark}
        size="small"
      />

      <FormSubTitle>Referansekobling</FormSubTitle>

      <div className="form-group">
        <label htmlFor="name">Individuell URL</label>
        <input
          type="text"
          name="contextualUrl"
          ref={register}
          className={`form-control ${errors.contextualUrl ? "is-invalid" : "valid"}`}
        />

        <FieldError error={errors.contextualUrl}></FieldError>
      </div>

      <div className="form-group">
        <label htmlFor="name">Individuell kommentar</label>

        <textarea
          name="contextualComment"
          ref={register}
          rows={4}
          className={`form-control ${errors.contextualComment ? "is-invalid" : "valid"}`}
        />

        <FieldError error={errors.contextualComment}></FieldError>
      </div>

      <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Oppretter...</span>
          </>
        ) : (
          <span>Opprett og tilknytt</span>
        )}
      </button>
    </form>
  );
};

export default CreateEntityReferenceForm;
