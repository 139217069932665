import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { ButtonToolbar, Dropdown, DropdownButton, Button } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { NavLink, useHistory } from "react-router-dom";
import {
  GET_HISTORY_REFERENCES_ROUTE,
  GET_HISTORY_PRISONCAMPS_ROUTE,
  GET_HISTORY_PERSONS_ROUTE,
  GET_HISTORY_ROUTE,
  ROUTES,
} from "../../../lib/Router/routes";
import { DELETE_HISTORY_MUTATION, TOGGLE_PUBLISH_HISTORY_MUTATION } from "../../../graphql/mutations";
import { GET_HISTORY_QUERY } from "../../../graphql/queries";
import { IDeleteHistoryMutationInput, IDeleteHistoryMutationResult } from "../../../interfaces/mutations/IDeleteHistoryMutation";
import { IGetHistoryQueryFilter, IGetHistoryQueryResult } from "../../../interfaces/queries/IGetHistoryQuery";
import { HistoryRouter } from "../../../lib/Router/Router";
import Audit from "../../_common/Audit/Audit";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import { useAuth } from "../../../lib/hooks/useAuth";
import PublishStatus from "../../_common/PublishStatus/PublishStatus";
import {
  ITogglePublishHistoryMutationResult,
  ITogglePublishHistoryMutationInput,
} from "../../../interfaces/mutations/ITogglePublishHistoryMutation";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface IEditHistoryProps {
  id: number;
}

const EditHistory: React.FunctionComponent<IEditHistoryProps> = (props) => {
  const { isAdmin } = useAuth();
  const history = useHistory();

  const { loading, error, data } = useQuery<IGetHistoryQueryResult, IGetHistoryQueryFilter>(GET_HISTORY_QUERY, {
    variables: { filter: { id: props.id } },
  });

  const [deleteHistory] = useMutation<IDeleteHistoryMutationResult, IDeleteHistoryMutationInput>(DELETE_HISTORY_MUTATION);
  const [togglePublishHistory, { loading: togglePublishHistoryLoading }] = useMutation<
    ITogglePublishHistoryMutationResult,
    ITogglePublishHistoryMutationInput
  >(TOGGLE_PUBLISH_HISTORY_MUTATION);
  if (error) return <QueryError apolloError={error}/>;
  if (loading) return <Loading />;
  if (!data || !data.history) return <DataNotFound />;

  const onDeleteHistory = async (Id: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette historie: ${data.history.title}?`);

    if (deleteConfirmed) {
      await deleteHistory({
        variables: {
          input: {
            id: Id,
          },
        },
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteHistory)) {
            handleMutationSuccess(result.data);
            history.push(ROUTES.AUTHORIZED.HISTORIES_ROUTE);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  const onTogglePublishHistory = async (historyId: number, publish: boolean) => {
    await togglePublishHistory({
      variables: {
        input: {
          id: historyId,
          publish: publish,
        },
      },
      refetchQueries: [{ query: GET_HISTORY_QUERY, variables: { filter: { id: historyId } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.togglePublishHistory)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h1">{data.history.title}</h1>

        <div>
          {isAdmin() && (
            <ButtonToolbar className="d-flex justify-content-end">
              {data.history.publishStatus.isPublished ? (
                <Button
                  variant="warning"
                  size="sm"
                  className="mr-1"
                  disabled={togglePublishHistoryLoading}
                  onClick={(event: any) => {
                    event.preventDefault();
                    onTogglePublishHistory(data.history.id, !data.history.publishStatus.isPublished);
                  }}>
                  {togglePublishHistoryLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Avpubliserer...</span>
                    </>
                  ) : (
                    <span> Avpubliser</span>
                  )}
                </Button>
              ) : (
                <Button
                  variant="success"
                  size="sm"
                  className="mr-1"
                  disabled={togglePublishHistoryLoading}
                  onClick={(event: any) => {
                    event.preventDefault();
                    onTogglePublishHistory(data.history.id, !data.history.publishStatus.isPublished);
                  }}>
                  {togglePublishHistoryLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Publiserer...</span>
                    </>
                  ) : (
                    <span> Publiser</span>
                  )}
                </Button>
              )}

              <DropdownButton size="sm" variant="secondary" alignRight id="dropdown-basic-button" title="Flere valg">
                <Dropdown.Item
                  className="text-danger"
                  eventKey="1"
                  onClick={() => {
                    onDeleteHistory(data.history.id);
                  }}>
                  <FiTrash2 /> Slett historie
                </Dropdown.Item>
              </DropdownButton>
            </ButtonToolbar>
          )}

          <Audit audit={data.history.audit}></Audit>
          <PublishStatus publishStatus={data.history.publishStatus}></PublishStatus>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <NavLink to={GET_HISTORY_ROUTE(data.history.id)} exact className="nav-link" activeClassName="active bg-white">
                Generelt
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={GET_HISTORY_PERSONS_ROUTE(data.history.id)} exact className="nav-link" activeClassName="active bg-white">
                Personer
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={GET_HISTORY_PRISONCAMPS_ROUTE(data.history.id)} exact className="nav-link" activeClassName="active bg-white">
                Fangesteder
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={GET_HISTORY_REFERENCES_ROUTE(data.history.id)} exact className="nav-link" activeClassName="active bg-white">
                Referanser
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <HistoryRouter history={data.history}></HistoryRouter>
        </div>
      </div>
    </div>
  );
};

export default EditHistory;
